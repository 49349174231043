.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.swiper-caption {
    position: absolute;
    bottom:0;
    left:0;
    padding: 16px;
    color:#fff;
    font-size: 12px;
}

.swiper-button-prev,
.swiper-button-next {
    color:#000;
}

.swiper-pagination-bullet-active {
    background:#000;
}