.vegas {
  width: 100%;
  height: 70vh;
  position: relative;
}

.vegas-timer {
  // background-color: @blue;
}

.vegas-timer-progress {
  background-color: @blue !important;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .vegas {
    height: 40vh;
  }
}

// xs styles
@media (max-width: 767px) {

}