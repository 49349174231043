@labelCol: #000;
@inputCol: #000;
@backgroundCol: transparent;
@formFontSize: 1.2em;

.text-input,
.custom-select,
.custom-submit,
.custom-textarea {
  display:block;
  width:100%;
  height: 35px;
  padding: 0;
  font-size: @formFontSize;
  font-family: @font-family-base;
  font-weight: 300;
  letter-spacing: 0.05em;
  color: @inputCol;
  background-color: @backgroundCol;
  appearance: none;
  outline: none;
  border: 0;
  border-radius: 0px;
}

.custom-select {
  height: 38px;
  padding-right: 30px;
  color: @inputCol;
  background: url("../img/core/arrow.svg") right center no-repeat;
}

.custom-textarea {
  display:block;
  height: 100px;
  border:1px solid #000;
}

.custom-submit {
  color: #fff;
  background: #000;
  font-size: @formFontSize;
  text-align: center;
  transition: 0.5s ease;
  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
}

label {
  display:block;
  color: @labelCol;
  font-weight: bold;
}

::placeholder {
  color: #767676;
}

.error {
  color: red !important;
}


// md styles
@media (max-width: 1199px) {}

// sm styles
@media (max-width: 991px) {}

// xs styles
@media (max-width: 767px) {}