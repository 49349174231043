.wave {
  background: url('/img/core/wave-outline.svg');
  background-size: 200vw;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 0;
  width: 6400px;
  height: 80vh;
  animation: wave 30s alternate infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.05;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

.bg-promo1 {
  background-image: url('/img/home/promo1.jpg');
}
.bg-promo2 {
  background-image: url('/img/home/promo2.jpg');
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .wave {
    width: 2400px;
    height: 70vh;
  }
}

// xs styles
@media (max-width: 767px) {

}