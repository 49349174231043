.menu-horizontal {
	list-style: none;
	padding: 0;
	margin: 10px 0;
	text-align: center;
	li {
		display: inline-block;
		list-style: none;
    	padding: 0;
		margin-right: 10px;
		text-transform: uppercase;
		a {
			transition: 0.5s ease;
			text-decoration: none;
			&:hover {
				transition: 0.5s ease;
		 		text-decoration: underline;
			}
		}
		&:last-child {
			margin-right: 0px;
		}
	}
}

.logo {
  width: 180px;
  svg {
    max-width: 100%;
  }
}


.menu {
  width: 100%;
  position: fixed;
  top:0;
  left: 0;
  background: white;
}
.nav {
  // display: none;
  display: block;
}
.menu-frosted {
  transition: 1s ease all;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 0px;
  // border: 1px solid rgba( 255, 255, 255, 0.18 );
  .nav {
    display: block;
  }
}
body {
  padding-top: 120px;
}

.nav {
  li {
    list-style: none;
  }
}
.mobile-nav {
  position: fixed;
  right: -200vw;
  bottom:0;
  transition: 1s ease all;
  height: 100vh;
  width: 100%;
  li {
    list-style: none;
    width: 100%;
    text-align: center;
    font-size: 12vw;
  }
}
.mobile-nav-active {
  right: 0;
}
.burger {
  right: 20px;
}
.hamburger-inner {
  background-color: @blue !important;
  &:before,
  &:after {
    background-color: @blue !important;
  }
}
.is-active {
  .hamburger-inner {
    background-color: @white !important;
    &:before,
    &:after, {
      background-color: @white !important;
    }
  }
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .logo {
    width: 100px;
  }
}

// xs styles
@media (max-width: 767px) {

}