
.sans-heading {
  font-family: 'Oswald', sans-serif;
}

.sans-copy {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}

.sans-copy-regular {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}



@fontxl: 64px;
@fontlg: 24px;
@fontmd: 20px;
@fontsm: 18px;
@fontxs: 16px;
@fontxxs: 12px;

.font-xl {
  font-size: @fontxl;
}
.font-lg {
  font-size: @fontlg;
}
.font-md {
  font-size: @fontmd;
}
.font-sm {
  font-size: @fontsm;
}
.font-xs {
  font-size: @fontxs;
}
.font-xxs {
  font-size: @fontxxs;
}


@media (max-width: 991px ) {
  @fontxl: 50px;
  @fontlg: 35px;
  @fontmd: 15px;
  @fontxs: 10px;

  .font-xl {
    font-size: @fontxl;
  }
  .font-lg {
    font-size: @fontlg;
  }
  .font-md {
    font-size: @fontmd;
  }
  .font-xs {
    font-size: @fontxs;
  }

}